<template>
  <b-container fluid>
    <b-row class="heading_alignment">
      <b-col md="12" id="testseries">
        <iq-card style="padding-left:20px; padding-right:20px" class="p-3">
          <template v-slot:headerTitle>
            <h4 class="card-title">
                <i class="fa fa-solid fa-graduation-cap" aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
                {{cvCardTitle}}
              <span class="btn_in_header">
                <b-button style="float:right;" variant="primary" @click="goToTestPrepPdtPage" v-if="moreBatchesToBuy && userData.user_role == 'USERROLE11114'">{{cvbtnGidePrep}}</b-button>
                <b-button variant="primary" @click="goToGIDEPrepDashboard" class="m-2" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">{{cvbtnGIDEPrepDashboard}}</b-button>
                <b-button variant="primary" @click="goBack()" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">Go Back</b-button>
              </span>
            </h4>
            <span v-if="userDetails"><b>Student Name:</b>&nbsp;<span class="primary-color" @click="gotoUserProfile(userDetails.user_id)">{{userDetails.user_name}}</span></span>
            <div :key="refreshTheFilterBadges" class="mt-2">
              <b-badge v-for="(subjectModuleListAnalyticsObj, index) of subjectModuleListAnalyticsObjList" :key="index+subjectModuleListAnalyticsObj.interest_id" title="Show active questions only" :style="setTagStyles(subjectModuleListAnalyticsObj.interest_id)" class="tags mr-2 mb-2 font-weight-normal" variant="none" @click="filterTestSeriesModules(subjectModuleListAnalyticsObj.interest_id)">
                <span>{{ subjectModuleListAnalyticsObj.subject_name }}:&nbsp; {{ subjectModuleListAnalyticsObj.module_count }} </span>
              </b-badge>
            </div>
          </template>
        </iq-card>
        <iq-card style="padding-left:20px; padding-right:20px" class="p-3">
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
                <div v-html="toastMsg"></div>
              </b-toast>

              <b-col class="container_minheight" md="12" v-if="subjModuleListObj && subjModuleListObj.length > 0">
                <b-table
                  hover
                  responsive
                  :items="subjModuleListObj"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(id)="data">
                    <span>{{subjModuleListObj.indexOf(data.item)+1}}</span>
                  </template>

                  <template v-slot:cell(no_of_attempts)="data">
                    <span>{{data.item.no_of_attempts >= 1 ? data.item.no_of_attempts : 0}}</span>
                    <b-button variant="mr-1" title="View Assessment Result" @click="goToResults(data.item.app_id)" v-if="data.item.app_id"><i class="fa-solid fa-square-poll-vertical font-size-20"></i></b-button>
                  </template>

                  <template v-slot:cell(avg_score_percentage)="data">
                    <span>{{data.item.avg_score_percentage ? data.item.avg_score_percentage.toFixed(2) : "0"}}%</span>
                  </template>

                  <template v-slot:cell(subject_module_name)="data">
                    <span>
                      {{data.item.subject_module_name}}<br>
                      <small>Id: {{data.item.app_id}}</small>
                    </span><br><br>
                    <b-button variant="mr-1" title="View Tutorial" @click="showblogViewDialog(data.item)" v-if="data.item.app_id && VUE_APP_ENV != 'prod'"><i class="fa-solid fa-book-open-reader font-size-20"></i></b-button>
                    <b-button variant="mr-1" title="Take Assessment" @click="goToAppDetail(data.item.app_id)" v-if="data.item.app_id"><i class="fa-solid fa-file-pen font-size-20"></i></b-button>
                    <b-button variant="mr-1" title="View Assessment Result" @click="goToResults(data.item.app_id)" v-if="data.item.app_id"><i class="fa-solid fa-square-poll-vertical font-size-20"></i></b-button>
                  </template>

                  <template v-slot:cell(subject_name)="data">
                    <b-badge class="pointer ml-1" size="sm" :style="getStateColorCode(data.item.subject_name)">{{data.item.subject_name }}</b-badge>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- Gide BLog View -->
    <b-modal
      scrollable
      :title="cvViewModalHeader"
      size="xxl"
    >
      <gideBlogView :propOpenedInModal="true" :propBlogVueObj="blogViewObj"/>
    </b-modal><!-- Gide BLog View -->
    </b-container>
    </template>

<script>
import { TPrepSubjectModules } from "../../../FackApi/api/TPrepSubjectModule"
import { socialvue } from "../../../config/pluginInit"
import { TprepSessions } from "../../../FackApi/api/TprepSession"
import TestSeriesConfigJson from "../../../FackApi/json/TestSeries.json"
import { User } from "../../../FackApi/api/user"
import gideBlogView from "../../Public/GideBlogView.vue"

export default {
  name: "TPrepModuleList",
  components: {
    gideBlogView
  },
  data () {
    return {
      VUE_APP_ENV: process.env.VUE_APP_ENV,
      TestSeriesConfigJson: TestSeriesConfigJson,
      apiName: "tprep_subject_module_report_list",
      subjectId: null,
      subjModuleListObj: [],
      subjectModuleResultListRespObj: [],
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Module", key: "subject_module_name", class: "text-left align-text-top w-200px", sortable: true },
        { label: "Subject", key: "subject_name", class: "text-left align-text-top", sortable: true },
        { label: "No. of Attempts", key: "no_of_attempts", class: "text-right align-text-top", sortable: true },
        { label: "Avg Score (%)", key: "avg_score_percentage", class: "text-right align-text-top", sortable: true }
      ],
      cvSearchText: "Type to Search",
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 200,
      cvCardTitle: `${this.$route.params.subjId.split("TESTSERIES_")[1].split("_MOCK")[0]} TestSeries`,
      sortBy: "name",
      sortDesc: false,
      userDetails: null,
      cvbtnGidePrep: "Add (+)",
      cvbtnGIDEPrepDashboard: "TestSeries Admin Dashboard",
      showModelblogView: false,
      blogViewObj: null,
      cvViewModalHeader: "TestSeries",
      cvbtnModalCancel: "Close",
      moreBatchesToBuy: false,
      userSubjectInterest: null,
      gide_user_id: null,
      subjectModuleListAnalyticsObjList: [],
      subjectModuleFilterMap: {}, // indicated currently active filters
      whereFilter: {
        interest_id: this.$route.params.subjId,
        testseries_module_type: "ALL"
      },
      refreshTheFilterBadges: 0,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "TestSeries Module List"
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    let allowedEmailDomains = process.env.VUE_APP_TRUSTED_EMAIL_DOMAINS.split(",")
    for (let emailDomain of allowedEmailDomains) {
      if (this.userData.user_email.includes(emailDomain)) {
        this.VUE_APP_ENV = "local"
      }
    }
    console.log(this.VUE_APP_ENV)
  },
  async mounted () {
    socialvue.index()

    this.gide_user_id = this.$route.query.gide_user_id ? this.$route.query.gide_user_id : this.userData.user_id

    await this.getUserSubjectInterest()
    // If a new batch exists then first add the session so that all the papaers get loaded
    await this.checkPayment()
    this.getModuleListAnalytics()
    this.getModuleList()
  },
  methods: {
    /**
      * goto User Profile
      */
    goBack () {
      this.$router.back()
    },
    /**
     * goto User Profile
     */
    gotoUserProfile (UserId) {
      window.open(`/user/${UserId}`)
    },
    /**
     * getUserSubjectInterest
     */
    async getUserSubjectInterest () {
      try {
        const userInterestListResp = await User.userInterestList(this, this.gide_user_id)
        if (!userInterestListResp) {
          this.$router.back()
          return
        }

        this.userSubjectInterest = userInterestListResp.resp_data.interest_list.INTERESTCAT11117.map(e => e.interest_id)
      }
      catch (err) {
        console.error("Exception in getUserSubjectInterest and err: ", err)
      }
    },
    /**
     * getModuleList
     */
    async getModuleList () {
      try {
        const payload = {}
        if (this.$route.query.gide_user_id) {
          payload.gide_user_id = this.$route.query.gide_user_id
        }

        payload.filter = this.whereFilter

        const subjectModuleListResp = await TPrepSubjectModules.tprepSubjectModuleReportList(this, payload)
        if (subjectModuleListResp && subjectModuleListResp.resp_status) {
          this.subjModuleListObj = subjectModuleListResp.resp_data.data
          let attemptsData = subjectModuleListResp.attempts_data

          if (attemptsData) {
            for (let i of this.subjModuleListObj) {
              let attempts = attemptsData.find(e => e.app_id == i.app_id)
              if (attempts) {
                i.no_of_attempts = attempts.no_of_attempts
                i.avg_score_percentage = attempts.avg_score_percentage
              }
            }
          }
          this.totalRows = subjectModuleListResp.resp_data_count
          this.userDetails = subjectModuleListResp.user_details
        }
      }
      catch (err) {
        console.error("Exception in getModuleList and err: ", err)
      }
    },

    /**
     * getModuleListAnalytics
     */
    async getModuleListAnalytics () {
      try {
        const payload = {}

        if (this.$route.query.gide_user_id) {
          payload.gide_user_id = this.$route.query.gide_user_id
        }

        payload.filter = this.whereFilter
        const subjectModuleListAnalyticsResp = await TPrepSubjectModules.tprepSubjectModuleReportListAnalytics(this, payload)
        if (!subjectModuleListAnalyticsResp.resp_status) {
          this.toastMsg = "Could not load Analytics"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        this.subjectModuleListAnalyticsObjList = subjectModuleListAnalyticsResp.resp_data.data

        // setting the filter map for various subject module types (e.g. TESTSERIES_SAT_PRACTICE_ENG, TESTSERIES_SAT_PRACTICE_MATH, TESTSERIES_SAT_MOCK)
        this.subjectModuleListAnalyticsObjList.forEach((e) => {
          this.subjectModuleFilterMap[e.interest_id] = 0 // unset all the filters
        })
      }
      catch (err) {
        console.error("Exception in getModuleListAnalytics and err: ", err)
      }
    },
    /**
     * checkPayment
     */
    async checkPayment () {
      try {
        // check if the user has a session, if it has then redirect the user to teest_prep
        let hasUserPaid = await TprepSessions.tprep_sessionCheckPayment(this, { gide_user_id: this.$route.query.gide_user_id ? this.$route.query.gide_user_id : this.gide_user_id, user_subject_interest: this.userSubjectInterest })
        if (!hasUserPaid.resp_status) {
          this.$router.back()
        }
        else if (hasUserPaid.modules_not_purchased.length > 0) {
          this.moreBatchesToBuy = false
        }
      }
      catch (error) {
        console.error("Exception in checkPayment and err: ", error)
      }
    },
    /**
     * goTo Quiz/App Details
     */
    goToAppDetail (appId) {
      if (appId) {
        this.$router.push(`/quiz_detail/${appId}`)
      }
    },
    /**
     * goTo Quiz/App Results
     */
    goToResults (appId) {
      if (appId && this.$route.query.gide_user_id) {
        this.$router.push(`/result_list/${appId}?gide_user_id=${this.$route.query.gide_user_id}`)
      }
      else if (appId) {
        this.$router.push(`/result_list/${appId}`)
      }
    },
    /**
     * goToTestPrepPdtPage
     */
    goToTestPrepPdtPage () {
      this.$router.push(`/testseries_product_exam/${TestSeriesConfigJson.TESTSERIES_SAT_PRODUCT_PAGE_APP_ID}`)
    },
    /**
     * goToGIDEPrepDashboard
     */
    goToGIDEPrepDashboard () {
      this.$router.push("/testseries_admin_dashboard")
    },
    /**
     * getResultAnalysis
     */
    async getResultAnalysis (result) {
      this.$router.push(`/analyse_app_result/${result.app_id}/${this.gide_user_id}`)
    },
    /**
     * get State ColorCode
     */
    getStateColorCodev0 (name = null) {
      if (name.toLowerCase().includes("mock")) {
        return `vertical-align: middle; color: #FFF; background: #e5252c !important;`
      }
      else if (name.toLowerCase().includes("eng")) {
        return `vertical-align: middle; color: #FFF; background: #994499 !important;`
      }
      else if (name.toLowerCase().includes("math")) {
        return `vertical-align: middle; color: #FFF; background: #109618 !important;`
      }
      else {
        return `vertical-align: middle; color: #FFF; background: #FF9900 !important;`
      }
    },
    /**
     * get State ColorCode
     */
    getStateColorCode (name = null) {
      if (name.toUpperCase().includes("ENG")) {
        return `vertical-align: middle; color: #FFF; background: ${TestSeriesConfigJson.TESTSERIES_ENG_BADGE_COLOR} !important;`
      }
      else if (name.toUpperCase().includes("MATH")) {
        return `vertical-align: middle; color: #FFF; background: ${TestSeriesConfigJson.TESTSERIES_MATH_BADGE_COLOR} !important;`
      }
      else if (name.toUpperCase().includes("MOCK")) {
        return `vertical-align: middle; color: #FFF; background: ${TestSeriesConfigJson.TESTSERIES_MOCK_BADGE_COLOR} !important;`
      }
    },
    /**
     * setTagStyles
     */
    setTagStyles (interestId) {
      let styles = "cursor:pointer;font-size:1rem;"
      if (interestId.toUpperCase().includes("ENG") && this.subjectModuleFilterMap[interestId] == 1) {
        styles += `background-color:${TestSeriesConfigJson.TESTSERIES_ENG_BADGE_COLOR};color:#fff;`
      }
      else if (interestId.toUpperCase().includes("ENG")) {
        styles += `border:1px solid ${TestSeriesConfigJson.TESTSERIES_ENG_BADGE_COLOR};color:${TestSeriesConfigJson.TESTSERIES_ENG_BADGE_COLOR};`
      }

      if (interestId.toUpperCase().includes("MATH") && this.subjectModuleFilterMap[interestId] == 1) {
        styles += `background-color:${TestSeriesConfigJson.TESTSERIES_MATH_BADGE_COLOR};color:#fff;`
      }
      else if (interestId.toUpperCase().includes("MATH")) {
        styles += `border:1px solid ${TestSeriesConfigJson.TESTSERIES_MATH_BADGE_COLOR};color:${TestSeriesConfigJson.TESTSERIES_MATH_BADGE_COLOR};`
      }
      // We can add multiple subjects here as we extend the exsams we support on our platform

      if (interestId.toUpperCase().includes("MOCK") && this.subjectModuleFilterMap[interestId] == 1) {
        styles += `background-color:${TestSeriesConfigJson.TESTSERIES_MOCK_BADGE_COLOR};color:#fff;`
      }
      else if (interestId.toUpperCase().includes("MOCK")) {
        styles += `border:1px solid ${TestSeriesConfigJson.TESTSERIES_MOCK_BADGE_COLOR};color:${TestSeriesConfigJson.TESTSERIES_MOCK_BADGE_COLOR};`
      }
      return styles
    },
    /**
     * show blog View Dialog
     */
    showblogViewDialog (item) {
      try {
        window.open(`/blog_view/BLOG${item.app_id}`, "_blank")
        /*
          this.blogViewObj = {
            blog_id: "BLOG" + item.app_id
          }
          this.showModelblogView = true
        */
      }
      catch (err) {
        console.error("Exception occurred at showblogViewDialog() and Exception:", err.message)
      }
    },
    /**
     * filterTestSeriesModules
     */
    filterTestSeriesModules (interestId) {
      this.subjectModuleListAnalyticsObjList.forEach((e) => {
        this.subjectModuleFilterMap[e.interest_id] = 0 // unset all the filters
      })

      this.subjectModuleFilterMap[interestId] = 1
      this.refreshTheFilterBadges += 1
      this.whereFilter.testseries_module_type = interestId
      this.getModuleList()
    }
  }
}
</script>

<style>
  .w-full{
    width: 100%;
  }
</style>
